import MarketItem from "../components/marketItem"
import React from "react"

function MarketsList(props) {
  const markets = props.markets;
  if (markets.length === 0) {
    return (
      <div>
        No markets open :(
      </div>
    )
  }
  const marketItems = markets.map((marketData) =>
    <MarketItem marketData={marketData} key={marketData.name} />
  );
  return (
    <div>
      {marketItems}
    </div>
  )
}
export default MarketsList
