import React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import MarketsList from "../components/marketsList"

import markets from "../data/markets"
import moment from 'moment';

function IndexPage(props) {
  const todayDate = moment(new Date());
  const tomorrowDate = moment(new Date()).add(1,'days');
  const [marketsOpenToday, setMarketsOpenToday] = useState([]);
  const [marketsOpenTomorrow, setMarketsOpenTomorrow] = useState([]);
  useEffect(() => {
    const todayDate = moment(new Date());
    const tomorrowDate = moment(new Date()).add(1,'days');
    const dayOfWeek = todayDate.day();
    const dayOfWeekTomorrow = tomorrowDate.day();
    const todayMarkets = markets.filter((marketData) => {
      return marketData.daysOfWeekOpen.includes(dayOfWeek);
    });
    const tomorrowMarkets = markets.filter((marketData) => {
      return marketData.daysOfWeekOpen.includes(dayOfWeekTomorrow);
    });
    setMarketsOpenToday(todayMarkets);
    setMarketsOpenTomorrow(tomorrowMarkets);
  }, []);
  return (
    <Layout>
      <div className="container">
        <SEO title="Bay Area Farmers Markets" />
        <h1>bay farmers</h1>
        <br/>
        <p>Farmers markets open today and tomorrow in the San Francisco Bay Area.</p>
        <br/>
        <h3>Today, {todayDate.format('MMMM Do')}</h3>
        <MarketsList markets={marketsOpenToday}/>
        <br/>
        <h3>Tomorrow, {tomorrowDate.format('MMMM Do')}</h3>
        <MarketsList markets={marketsOpenTomorrow}/>
      </div>
    </Layout>
  )
}

export default IndexPage
