// daysOfWeekOpen started on Sunday at 0
const markets = [
  {
    name: "Thrive City",
    daysOfWeekOpen: [0],
    timeStart: "8am",
    timeEnd: "1pm",
    neighborhoods: ["Mission Bay"],
    address: "1 Warriors Way",
    googleMapsQuery: "1+Warriors+Way,+San+Francisco,+CA+94158"
  },
  {
    name: "Inner Sunset",
    daysOfWeekOpen: [0],
    timeStart: "9am",
    timeEnd: "1pm",
    neighborhoods: ["Inner Sunset"],
    address: "1315 8th Ave",
    googleMapsQuery: "Inner+Sunset+Farmers'+Market"
  },
  {
    name: "Clement Street",
    daysOfWeekOpen: [0],
    timeStart: "9am",
    timeEnd: "2pm",
    neighborhoods: ["Inner Richmond"],
    address: "1315 8th Ave",
    googleMapsQuery: "Clement+St.+Farmers+Market"
  },
  {
    name: "Stonestown",
    daysOfWeekOpen: [0],
    timeStart: "9am",
    timeEnd: "2pm",
    neighborhoods: ["Ingleside", "Merced"],
    address: "3251 20th Avenue",
    googleMapsQuery: "Stonestown+Farmers+Market"
  },
  {
    name: "Fort Mason",
    daysOfWeekOpen: [0],
    timeStart: "9am",
    timeEnd: "1pm",
    neighborhoods: ["Marina"],
    address: "Fort Mason Center, Marina Blvd",
    googleMapsQuery: "Fort+Mason+Center+Farmers'+Market"
  },
  {
    name: "Divisadero",
    daysOfWeekOpen: [0],
    timeStart: "10am",
    timeEnd: "2pm",
    neighborhoods: ["Divisadero"],
    googleMapsQuery: "Divisadero+Farmers'+Market"
  },
  {
    name: "Heart of the City",
    daysOfWeekOpen: [0],
    timeStart: "7am",
    timeEnd: "5pm",
    neighborhoods: ["Civic Center"],
    googleMapsQuery: "Heart+of+the+City+Farmers+Market"
  },
  {
    name: "Heart of the City",
    daysOfWeekOpen: [3, 5],
    timeStart: "7am",
    timeEnd: "2pm",
    neighborhoods: ["Civic Center"],
    googleMapsQuery: "Heart+of+the+City+Farmers+Market"
  },
  {
    name: "Parnassus (UCSF)",
    daysOfWeekOpen: [3],
    timeStart: "10am",
    timeEnd: "3pm",
    neighborhoods: ["Inner Sunset", "UCSF"],
    googleMapsQuery: "500+Parnassus+Ave,+San+Francisco,+CA+94143"
  },
  {
    name: "Mission Bay",
    daysOfWeekOpen: [3],
    timeStart: "10am",
    timeEnd: "2pm",
    neighborhoods: ["Mission Bay", "SoMa"],
    googleMapsQuery: "550+Gene+Friend+Way,+San+Francisco,+CA+94158"
  },
  {
    name: "Kaiser Permanente",
    daysOfWeekOpen: [3],
    timeStart: "10am",
    timeEnd: "2pm",
    neighborhoods: ["Pacific Heights", "Western Addition"],
    googleMapsQuery: "Kaiser+Permanente+San+Francisco+Medical+Center+and+Medical+Offices"
  },
  {
    name: "Veterans Affairs (VA)",
    daysOfWeekOpen: [3],
    timeStart: "10am",
    timeEnd: "2pm",
    neighborhoods: ["Outer Richmond"],
    googleMapsQuery: "San+Francisco+VA+Medical+Center",
  },
  {
    name: "Castro",
    daysOfWeekOpen: [3],
    timeStart: "4pm",
    timeEnd: "8pm",
    neighborhoods: ["Castro"],
    googleMapsQuery: "Castro+Farmers’+Market"
  },
  {
    name: "Ferry Plaza",
    daysOfWeekOpen: [2, 4],
    timeStart: "10am",
    timeEnd: "2pm",
    neighborhoods: ["Embarcadero"],
    googleMapsQuery: "Ferry+Plaza+Farmers+Market",
  },
  {
    name: "Ferry Plaza",
    daysOfWeekOpen: [6],
    timeStart: "8am",
    timeEnd: "2pm",
    neighborhoods: ["Embarcadero"],
    googleMapsQuery: "Ferry+Plaza+Farmers+Market"
  },
  {
    name: "Crocker Galleria",
    daysOfWeekOpen: [4],
    timeStart: "10am",
    timeEnd: "2pm",
    neighborhoods: ["FiDi", "Union Square"],
    googleMapsQuery: "San+Francisco+Farmers'+Market+at+Crocker+Galleria"
  },
  {
    name: "Mission Community",
    daysOfWeekOpen: [4],
    timeStart: "4pm",
    timeEnd: "8pm",
    neighborhoods: ["Mission"],
    googleMapsQuery: "Mission+Community+Market",
  },
  {
    name: "Alemany",
    daysOfWeekOpen: [6],
    timeStart: "6am",
    timeEnd: "2pm",
    neighborhoods: ["Bernal Heights", "Portola"],
    googleMapsQuery: "Alemany+Farmer's+Market"
  },
  {
    name: "Noe Valley",
    daysOfWeekOpen: [6],
    timeStart: "8am",
    timeEnd: "1pm",
    neighborhoods: ["Noe Valley"],
    googleMapsQuery: "Noe+Valley+Farmers+Market",
  },
  {
    name: "Bayview",
    daysOfWeekOpen: [6],
    timeStart: "9am",
    timeEnd: "1pm",
    neighborhoods: ["Bayview"],
    googleMapsQuery: "The+Bayview+Farmers+Market"
  },
  {
    name: "North Beach",
    daysOfWeekOpen: [6],
    monthsOpen: [4, 5, 6, 7, 8, 9, 10, 11],
    timeStart: "9am",
    timeEnd: "1pm",
    neighborhoods: ["North Beach"],
    googleMapsQuery: "North+Beach+Farmers+Market"
  },
  {
    name: "Fillmore",
    daysOfWeekOpen: [6],
    timeStart: "9am",
    timeEnd: "1pm",
    neighborhoods: ["Fillmore", "Japantown"],
    googleMapsQuery: "Fillmore+Farmers'+Market"
  },
]
export default markets;
