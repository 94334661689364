import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./marketItem.css"

function MarketItem(props) {
  const marketData = props.marketData;
  const neighborhoodsList = marketData.neighborhoods.map((neighborhood) =>
    <div className="neighborhood" key={neighborhood}>
      {neighborhood}
    </div>
  );
  const googleMapsQuery = marketData.googleMapsQuery;
  const googleLink = "https://maps.google.com/maps/search/?api=1&query=" + googleMapsQuery;
  return (
    <div className="market-item">
      <h4 className="content-hyphen">-</h4>
      <div className="content">
        <h4>{marketData.name}</h4>
        <div className="neighborhoods">
          {neighborhoodsList}
        </div>
        <div className="times">
          {marketData.timeStart} - {marketData.timeEnd} 
        </div>
        <br/>
        <a className="map-button" href={googleLink}>Take me there</a>
      </div>
    </div>
  )
}
export default MarketItem
